.navbar-container {
    width: calc(100% - 10rem);
    height: 100px;
    position: relative;
    display: flex;
    padding: 3rem 5rem 2rem 5rem;
}

.navbar-logo-container {
    max-height: 100px;
}

.navbar-logo {
    height: 100px;
}

.navbar-links{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.navbar-links ul {
    list-style: none;
    display: flex;
    padding: .5rem 1rem;
}

.navbar-links li {
    margin-right: 5rem;
}

.navbar-links li:last-child {
    margin-right: 0;
}

.navbar-links a{
    color: #000;
    font-size: 1.2rem;
    text-transform: capitalize;
}

.active-link {
    color: var(--baseLightColor) !important;
    font-weight: 700;
}

.navbar-links a:hover {
    color: var(--baseLightColor) !important;
}

.navbar-responsive-list-container {
    display: none;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100%;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.navbar-menu-btn {
    width: 45px;
    height: 4px;
    background-color: var(--baseLightColor);
    border-radius: 5px;
    margin-bottom: -20px;
    transition: all .5s ease-in-out;
    z-index: 1002;
}

.navbar-menu-btn::before,
.navbar-menu-btn::after {
    content: "";
    position: absolute;
    width: 45px;
    height: 4px;
    background-color: var(--baseLightColor);
    border-radius: 5px;
    transition: all .5s ease-in-out;
    z-index: 1002;
}

.navbar-menu-btn::before {
    transform: translateY(-13px);
}

.navbar-menu-btn::after {
    transform: translateY(13px);
}

.navbar-responsive-list-container.open-hmg .navbar-menu-btn {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
}

.navbar-responsive-list-container.open-hmg .navbar-menu-btn::before {
    transform: rotate(45deg) translate(35px, -35px);
}

.navbar-responsive-list-container.open-hmg .navbar-menu-btn::after {
    transform: rotate(-45deg) translate(35px, 35px);
}


.navbar-responsive-list {
    list-style: none;
    position: absolute;
    top: 0;
    right: -800px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 60px 25px;
    margin: 0;
    width: 80%;
    max-width: 400px;
    height: calc(100vh - 60px);
    background-color: var(--baseColor);
    z-index: 1001;
    transition: all .5s ease-in-out;
}

.right-list {
    right: 0 !important;
}

.navbar-responsive-list a, .navbar-responsive-list a:active, .navbar-responsive-list a:visited {
    color: #fff;
    font-size: 1.1rem;
    line-height: 1.5;
    text-transform: capitalize;
    
}

.navbar-responsive-list a:hover {
    color: var(--baseLightColor);
}

.navbar-responsive-list li {
    padding: 20px 10px;
    width: 70%;
    border-bottom: 2px dashed var(--baseLightColor);
}

@media only screen and (max-width: 1400px) {
    .navbar-links li {
        margin-right: 3rem;
    }   
}

@media only screen and (max-width: 992px) {
    .navbar-links{
        display: none;
    }

    .navbar-container {
        justify-content: space-between;
    }

    .navbar-responsive-list-container {
        display: flex;
    }
}

@media only screen and (max-width: 576px) {
    .navbar-container {
        width: calc(100% - 2rem);
        padding: 2rem 1rem;
        align-items: center;
    }

    .navbar-logo {
        height: 70px;
    }
}