.wrapper {
  align-items: stretch;
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
  background: #f4f7fc;
}

.wrapper::before {
  width: 100%;
  height: 264px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, var(--baseColor), var(--baseLightColor));
  content: " ";
}

.mainPrivate {
  z-index: 1;
  width: 100%;
  min-height: 100vh;
  min-width: 0;
  transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
}

@media only screen and (max-width: 992px) {
  /* .mainPrivate {
      overflow-y: hidden;
      width: 100vw;
      min-width: 100vw;
  } */
  .content {
      padding: 0 3rem;
  }
}

.content {
    margin-top: 3rem;
    padding: 0 0.5rem;
  }
  
  @media only screen and (min-width:992px) {
    .content{
        padding: 0 3rem;
    }
  }
  
  .container-fluid {
    width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-right: auto;
    margin-left: auto;
  }
  
  .header {
    position: relative;
    margin-bottom: 3rem;
  }
  
  .header-title {
    font-size: 1.65rem;
    color: #fff;
  }
  
  .header-subtitle {
    font-size: 0.94rem;
    color: #fff;
    margin-top: 10px;
  }
  
  .row-content {
    background: #fff;
    box-shadow: 0 4px 4px 0 rgb(0, 0, 0, 0.25);
  }
  
  .row-content-title {
    font-size: 20px;
    font-weight: bold;
  }

  .sub-content {
    padding: 2rem;
  }

  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;
    background-color: #00000080;
    z-index: 10001;
    overflow: auto;
  }  
  
  .popupInner {
    width: fit-content;
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    margin: 50px auto;
  }
  
  .popup-alert-container {
    padding: 4rem;
    display: flex;
    flex-direction: column;
  }

  .popup-alert-container input, .popup-alert-container textarea, .popup-alert-container select {
    background-color: #f2f2f2;
    border: none;
    border-radius: 12px;
    margin: 6px 0;
    padding: 15px 20px;
  }

  .popup-alert-container label {
    color: var(--baseColor);
    font-weight: 700;
  }

  .button-container button:first-child {
    margin-right: 1em;
  }

  .button-container button:last-child {
    margin-left: 1rem;
  }

  .request-table-container {
    width: 100%;
    overflow: auto;
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.request-table-preview {
  border-collapse: collapse;
  position: relative;
  border: 1px solid #e2e2e2;
}

.request-table-preview tr {
  text-align: left;
}

.request-table-preview th {
  color: var(--baseColor);
}

.request-table-preview th, .request-table-preview td {
  padding: 8px 24px;
  font-size: 14px;
  max-width: 250px;
  min-width: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 3;
}

.request-table-preview tbody tr:nth-child(even) {
  background-color: var(--baseLightColor);
  color: #fff !important;
  cursor: pointer;
}

.request-table-preview tbody tr:nth-child(odd) {
  color: var(--baseLightColor) !important;
  cursor: pointer;
}

.request-table-container::-webkit-scrollbar {
  width: 1em;
}
 
.request-table-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.request-table-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.projects-content-filter {
  margin-top: 2rem;
}

.projects-content-filter input {
  background-color: #f2f2f2;
  border: none;
  border-radius: 12px;
  width: 188px;
  padding: 1rem;
}

.detail-title {
  color: var(--baseColor);
  font-weight: 700;
}

@media only screen and (max-width: 576px) {
  .request-table-container {
    flex-direction: column;
  }

  .popup-alert-container {
    padding: 1rem;
  }

  .header {
    padding: 0 2rem;
  }

  .button-container button:last-child {
    margin-left: 0;
    margin-top: 10px;
  }

  .button-container button:nth-child(2) {
    margin-left: 0;
    margin-top: 10px;
  }
}

.detailed-image-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  position: relative;
}

.detailed-images {
  max-width: 150px;
  max-height: 200px;
}

.an-image-container {
  position: relative;
}

.an-image-container button, .an-image-container p {
  position: absolute;
  padding: .5rem 2rem !important;
  bottom: .5rem;
  left: 1.5rem;
  background-color: #ffffff62 !important;
  margin: 0;
}