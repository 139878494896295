:root {
    --baseColor: #92172E;
    --baseLightColor: #BB1638;
}

.container {
    min-height: 100vh;
    background-color: var(--baseColor);
    display: flex;
    align-items: center;
}

.landing-container {
    width: 100%;
    height: calc(100vh - 6rem);
    background-color: #fff;
    display: flex;
}

.logo-side {
    width: 40%;
    padding: 1rem 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.links-side {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.links-container {
    display: flex;
    padding: .2rem 0;
    height: 50%;
}

.big-link {
    width: 54.5%;
    position: relative;
}

.small-link {
    width: 45%;
    position: relative;
}

.acre-logo {
    max-width: 320px;
    max-height: 320px;
}

.small-explanation {
    font-size: .8rem;
    text-align: justify;
    color: #828282;
    max-width: 240px;
    line-height: 1.5;
    font-weight: 500;
}

.pd-t4 {
    padding-top: 4rem;
}

.pd-t1 {
    padding-top: 1rem;
}

.pd-b1 {
    padding-bottom: 1rem;
}

.mrg-t1 {
    margin-top: 1rem;
}

.divider {
    width: 125px;
    border: 1px solid #fff;
}

.link-content {
    width: 100%;
    height: 100%;
    background-color: #5252527a;
    color: #fff !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.link-content h3 {
    text-transform: capitalize;
}
.link-content h4 {
    text-transform: lowercase;
}

.link-content:hover {
    background-color: #bb163749 !important;
}

.bg-buildings {
    background-image: url('../../res/buildings.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-buildings .link-content {
    transition: all .2s cubic-bezier(1, 0, 0, 1)
}

.bg-buildings:hover .link-content:first-child {
    font-size: 1.2rem !important;
}

.bg-services {
    background-image: url('../../res/services.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-services .link-content {
    transition: all .2s cubic-bezier(1, 0, 0, 1)
}

.bg-services:hover .link-content:first-child {
    font-size: 1.2rem !important;
}

.bg-projects {
    background-image: url('../../res/projects.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-projects .link-content {
    transition: all .2s cubic-bezier(1, 0, 0, 1)
}

.bg-projects:hover .link-content:first-child {
    font-size: 1.2rem !important;
}

.bg-contact {
    background-image: url('../../res/contact.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-contact .link-content {
    transition: all .2s cubic-bezier(1, 0, 0, 1)
}

.bg-contact:hover .link-content:first-child {
    font-size: 1.2rem !important;
}

.link-content h3 {
    font-size: 1.4rem;
    letter-spacing: 3px;
}

.link-content h4 {
    font-size: 1rem;
    letter-spacing: 2px;
    color: #d2d2d2;
}

.mrg-r1p {
    margin-right: .5%;
}

.mrg-r1 {
    margin-right: 1rem;
}

.link {
    cursor: pointer;
}

.link:hover{
    background-color: #ffffffb0;
}

@media only screen and (max-width:992px) {

    .landing-container {
        flex-direction: column;
        margin: 3rem 0;
        height: auto;
        min-height: calc(100vh - 6rem);
    }

    .logo-side {
        width: 100%;
    }
    
    .links-side {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .links-container {
        height: 100%;
    }

    .link-content {
        height: 400px;
    }
}

@media only screen and (max-width: 576px) {
    .links-container {
        flex-direction: column;
    }   

    .big-link {
        width: 100%;
        margin: .5rem 0;
    }
    
    .small-link {
        width: 100%;
        margin: .5rem 0;
    }
}

.error-alert {
    display: none;
    opacity: 0;
    width: calc(100% - 1rem);
    padding: .5rem;
    margin-bottom: .5rem;
    color: #fff;
    background-color: var(--baseLightColor);
    border-radius: 8px;
    transition: all .5s ease-in;
}

.success-alert {
    color: #155725;
    background-color: #d4eddb;
    margin: 1rem 0;
    border: 1px solid #c3e6cc;
    border-radius: .25rem;
    padding: .8rem 1.2rem;
    position: relative;
  }



.unhidden {
    opacity: 1;
    display: block;
}

@media only screen and (min-height: 1600px) {
    .acre-logo {
        max-width: 540px;
        max-height: 600px;
    }

    .small-explanation {
        font-size: 1.1rem;
        max-width: 420px;
    }
}