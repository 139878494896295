.btn-primary {
    text-decoration: none;
    color: #fff !important;
    background-color: var(--baseColor);
    padding: 1rem 4rem;
    border-radius: 50rem;
    border: 1px solid #fff;
}

.btn-primary:hover {
    background-color: var(--baseLightColor);
}