body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6,ul,li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}