.not-found-404-main {
    background-color: var(--baseColor);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-found-404-text-container {
    background-color: #e2e2e29d;
    width: 70%;
    padding: 10%;
    color: #262626;
}

.marketplace-btn-back {
    padding: 10px 0;
    text-align: center;
    border-radius: 4px;
    width: 220px;
}

.marketplace-btn-back a, .marketplace-btn-back a:visited, .marketplace-btn-back a:active {
    color:#fff;
    display: block;
}

@media only screen and (max-width: 400px) {
    .marketplace-btn-back {
        width: 180px;
    }
}
