body {
  font-family: 'Avenir','Open Sans','Helvetica','Arial', sans-serif;
}

.mainpage {
  max-width: 2560px;
  margin: auto;
  overflow: hidden;
}

button, input {
  border: none;
  outline: none;
}

button {
  cursor: pointer;
}

::selection {
  background-color: var(--baseColor) !important;
  color: #fff !important;
}

::-moz-selection {
  background-color: var(--baseColor) !important;
  color: #fff !important;
}



/* .goog-logo-link {
  display:none !important;
} 
  
.goog-te-gadget{
  color: transparent !important;
}

#google_translate_element {
  z-index: 1000;
  position: fixed;
  bottom: -7.5px;
  right: 2rem;
  display: flex;
  content: '';
}

.goog-te-combo {
  padding: .5rem 1.5rem;
  color: #fff !important;
  margin: 0 !important;
  background-color: var(--baseLightColor) !important;
  border: none !important;
  border-radius: 50rem;
  font-size: 16px !important;
  font-family: 'Avenir', 'Open Sans', 'Helvetica', 'Arial', sans-serif !important;
  outline: none !important;
} */

.main-buttons-container {
  position: absolute;
  top: 5px;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1003;
}