.hakkimizda::before {
    width: 100%;
    height: 320px;
    position: absolute;
    top: 180px;
    left: 0;
    right: 0;
    background-image: url('../../res/hakkimizda.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* background: linear-gradient(to bottom, var(--baseColor), var(--baseLightColor)); */
    content: " ";
    z-index: -1;
  }

  .hakkimizda-top {
      padding-top: 250px;
  }

  .hakkimizda-title {
      color: #fff !important;
      font-weight: 700;
      font-size: 2rem;
      text-transform: uppercase;
  }

  .hakkimizda-aciklama {
      font-size: 1.2rem;
      padding-top: 50px;
      line-height: 2;
      word-spacing: 3px;
      text-align: justify;
      font-weight: 500;
  }