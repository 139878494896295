.btn-secondary {
    text-decoration: none !important;
    color: var(--baseColor) !important;
    border: 1px solid var(--baseColor) !important;
    background-color: #fff !important;
    padding: 1rem 4rem !important;
    border-radius: 50rem !important;
}

.btn-secondary:hover {
    background-color: var(--baseColor) !important;
    color: #fff !important;
}