.project-details-container {
    margin: 2rem 0;
}

.proje-detay-foto {
    width: 33%;
    height: auto;
    max-height: 350px;
    margin: 0 .1%;
    padding: .5rem 0;
}

.project-details-photo-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3rem;
}


.proje-detay-foto img {
    transition: all .3s ease-in-out;
    width: 100%;
    height: 100%;
}

.zoom-in {
    cursor: zoom-in;
}

.project-details-photo-container img:hover {
    transform: scale(1.02)
}

.full-size {
    width: 60% !important;
    height: auto !important;
    z-index: 10000;
    top: 0;
    left: 0;
    cursor: zoom-out;
    max-height: 1000px !important;
}

.fl-bg {
    position: fixed !important;
    width: 100% !important;
    height: 100vh !important;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background-color: #262626da;
}

.close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: var(--baseLightColor);
    color: #fff;
    padding: 1rem 2rem;
    border-radius: 50rem;
}

.close-btn:hover, .detay-onceki-foto:hover, .detay-sonraki-foto:hover {
    background-color: var(--baseColor);
}

.detay-onceki-foto, .detay-sonraki-foto {
    background-color: var(--baseLightColor);
    color: #fff;
    margin: auto;
    padding: 1rem 2rem;
    border-radius: 50rem;
}

.onceki-disabled {
    background-color: #8b8b8b;
    color: #d2d2d2;
    margin: auto;
    padding: 1rem 2rem;
    border-radius: 50rem;
}

.project-detail-title {
    color: var(--baseLightColor);
    font-weight: 700;
}

.project-details-explanation {
    font-size: 1.1rem;
}

.details-content h4 {
    color: var(--baseLightColor);
    margin-top: 1rem;
}

@media only screen and (max-width: 992px) {
    .proje-detay-foto {
        width: 49%;
    }
}

@media only screen and (max-width: 576px) {
    .proje-detay-foto {
        width: 100%;
    }
    .full-size {
        width: 100% !important;
        height: auto !important;
    }

    .detay-onceki-foto, .onceki-disabled {
        position: absolute;
        bottom: 4rem;
        left: 1rem;
    }
    .detay-sonraki-foto {
        position: absolute;
        bottom: 4rem;
        right: 1rem;
    }
}