.iletisim-container {
    width: 40%;
}

.harita-container {
    width: 50%;
    margin-left: 10%;
    min-height: 500px;
}

.flx-cont {
    display: flex;
}

.iletisim-title {
    color: #525252;
    font-size: 1.1rem;
}

.iletisim-aciklama {
    color: #626262;
    font-size: 1.2rem;
}

.iletisim-aciklama:hover {
    color: var(--baseColor) !important;
}

.iletisim-content {
    padding: .3rem 0;
    border-bottom: 1px solid #d2d2d2;
}

.iletisim-content a, .iletisim-content a:active, .iletisim-content:visited {
    color: #626262;
}

.iletisim-content a:hover {
    color: var(--baseColor) !important;
}

.harita {
    height: 250px;
}

.iletisim-header {
    color: var(--baseColor);
    font-weight: 700;
    font-size: 1.3rem;
}

.iletisim-left {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.iletisim-right {
    margin-left: 5%;
    width: 35%;
    display: flex;
    flex-direction: column;
}

.iletisim-right textarea {
    height: 180px;
}

.flex-wrap {
    display: flex;
}

.recaptcha-container {
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 992px) {
    .iletisim-container {
        width: 100%;
    }
    
    .harita-container {
        width: 100%;
        margin-top: 2rem;
        margin-left: 0;
    }

    .harita {
        margin-top: 25px;
    }
    
    .flx-cont {
    flex-direction: column;
    }

    .flex-wrap {
        flex-direction: column;
    }

    .iletisim-left {
        width: 100%;
    }

    .iletisim-right {
        width: 100%;
        margin: 0;
    }

    .recaptcha-container {
        justify-content: center;
    }
}