.fluid {
    min-height: calc(100vh - 164px);
    padding-right: 5rem;
    padding-left: 5rem;
}

.top-border {
    border-top: 5px solid var(--baseColor);
}

.fluid-container {
    padding: 2rem 0;
}

.box-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1%;
}

.box {
    width: 49.5%;
    height: 300px;
    position: relative;
    cursor: pointer;
}

.bg-blurred {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* filter: blur(3px); */
    z-index: -1;
}

.bg-produce {
    background-image: url('../../res/insaat4.png');
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
}

.bg-develop {
    background-image: url('../../res/proje3.png');
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
}

.bg-search {
    background-image: url('../../res/yatirim1.png');
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
}

.bg-manage {
    background-image: url('../../res/is2.png');
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
}

.box-content {
    width: 100%;
    height: 100%;
    background-color: #bb163749 !important;
    color: #fff !important;
    transition: all .2s cubic-bezier(1, 0, 0, 1)
}

.mrg-l05 {
    margin-left: .5rem;
}

.box-content h1{
    padding-top: 3rem;
    text-align: center;
}

.box-content p {
    padding-left: 3rem;
    padding-top: .5rem;
}
.flip-back-content {
    display: flex;
}
.flip-back-left {
    width: 50%;
}
.flip-back-right {
    width: 50%;
}

@media only screen and (max-width: 992px) {
    .box-container {
        flex-direction: column;
    }   

    .box {
        width: 100%;
        margin: 1% 0;
        height: 300px;
    }

    .flip-card {
        margin: 10px 0;
    }
}

@media only screen and (max-width: 576px) {
    .fluid {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .box-content p {
        padding-left: 0;
        padding-top: .5rem;
    }
}

@media only screen and (max-width: 460px) {
    .box-content h1{
        font-size: 1.5rem;
    }
}

.flip-card {
    background-color: transparent;
    width: 49.5%;
    height: 300px;
    perspective: 1000px;
    position: relative;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    /* text-align: center; */
    transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  /* .flip-card-front {
    background-color: #bb163749 !important;
    color: black;
  } */
  
  .flip-card-back {
    background-color: var(--baseColor);
    color: #fff;
    transform: rotateY(180deg);
  }

  @media only screen and (max-width: 1100px) {
    .flip-card {
        width: 100%;
      }
  }