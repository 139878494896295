.giris-yap-btn{
    border-radius: 50rem;
    cursor: pointer;
    background-color: var(--baseLightColor);
    color: #fff !important;
    margin: 0;
    padding: .5rem 1.5rem;
}

.giris-yap-btn:hover {
    background-color: var(--baseColor);
}