.sidebar-active-tab {
    margin-left: 4px !important;
    border-left: 3px solid #153d77;
    border-radius: 0px;
    font-weight: 700 !important;
}

.sidebar-content {
    background: #fff;
    transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.sidebar {
    min-width: 250px;
    max-width: 250px;
    transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
    background: transparent;
    border-right: 0;
    box-shadow: 0 0 2rem 0 rgba(0,0,0,0.05);
    z-index: 1;
}

.sidebar.toggled {
    margin-left: -250px;
}

.sidebar-brand-logo, sidebar-brand-logo:hover {
    padding: 1rem;
    background: var(--baseColor);
    font-size: 1.175rem;
    font-weight: 500;
    color: #fff;
    width: calc(100% - 2rem);
    display: block;
    text-decoration: none;
}

.sidebar-user-container {
    /* padding: 1.5rem 1rem 1rem; */
    padding: 2rem 1rem;
    background: #fff;
    color: var(--baseColor);
    text-align: center;
}

.sidebar-user-container img {
    max-width: 80%;
    max-height: 120px;
    vertical-align: middle;
    border-style: none;
}

.sidebar-link, a.sidebar-link {
    display: block;
    padding: 0.65rem 0.75rem;
    margin: 0 0.5rem;
    color: var(--baseColor);
    font-weight: 400;
    background: transparent;
    border-radius: 0.2rem;
    transition: background .1s ease-in-out;
    position: relative;
    text-decoration: none;
    cursor: pointer;
}

.sidebar-link:hover, a.sidebar-link:hover {
    color: var(--baseLightColor);
}

.sidebar-navigator {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    background: #fff;
}

#data-collapse {
    position: relative;
}

#data-collapse::before {
    content: " ";
    border: solid;
    border-width: 0 .1rem .1rem 0;
    display: inline-block;
    padding: 2.5px;
    transform: rotate(-45deg);
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    transition: all .2s ease-out;
}

@keyframes dropdownIconAnimation {
    from {opacity:0;}
    to {opacity: 1;}
}

#data-collapse:not(.collapsed):before {
    transform: rotate(45deg);
}

.sidebar-dropdown .sidebar-link {
    padding: 0.35rem 1.5rem 0.35rem 2.75rem;
    margin: 0 0.5rem;
    color: #6c757d;
    background: #fff;
    font-weight: 400;
    font-size: 80%;
}

@media only screen and (max-width: 1440px) {
    .sidebar {
        max-width: 230px;
        min-width: 230px;
    }
}

@media only screen and (max-width: 992px) {
    .sidebar {
        margin-left: -250px;
    }
    .sidebar.toggled {
        margin-left: 0;
    }
}

.mrt-1 {
    margin-right: 1rem;
}