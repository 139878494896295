.login-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    height: 100vh;
}

.login-content {
    width: 400px;
    background-color: #fff;
    box-shadow: 0 2px 2px rgba(0, 0 ,0, 0.2);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 80px 20px;
}

.main-background {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: url('../../res/anasayfa.png');
    filter: blur(12px);
}

.login-image {
    margin-bottom: 40px;
}

.login-inputs {
    display: flex;
    flex-direction: column;
}

.login-inputs input, .login-inputs button, .login-inputs textarea {
    padding: 15px 40px;
    outline: none;
}

.login-inputs input, .login-inputs textarea {
    background-color: #f2f2f2;
    border: none;
    border-radius: 12px;
    margin: 6px 0;
}

.login-inputs textarea {
    height: 120px;
}

.login-inputs button {
    background-color: var(--baseColor);
    border: none;
    border-radius: 50rem;
    color: #fff;
    margin-top: 20px;
    cursor: pointer;
}

.login-inputs button:hover {
    background-color: var(--baseLightColor);
}