.languages-controller {
    cursor: pointer;
}

.languages-controller ul {
    position: absolute;
    top: 3rem;
    right: -1.5rem;
    list-style: none;
    background-color: var(--baseLightColor);
    box-shadow: 0 0 10px rgba(0,0,0,.00075);
    border-radius: 4px;
}

.languages-controller ul li {
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.languages-controller ul li:hover {
    background-color: var(--baseColor);
}
.languages-controller ul li img {
    margin-right: 1rem;
}

.languages {
    display: flex;
    margin-left: 1rem;
}

.selected-languages {
    background-color: var(--baseColor);
}