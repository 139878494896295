.tabs {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.tabs button, .tabs a {
    width: 33%;
    padding: .5rem 0;
    font-size: 1.2rem;
    line-height: 1.5;
    background-color: var(--baseLightColor);
    color: #fff;
    transition: background-color .2s ease-in;
    text-align: center;
}

.tabs button:hover, .tabs a:hover {
    background-color: var(--baseColor);
}

.active-tab {
    background-color: var(--baseColor) !important;
}

.project-list {
    padding: 2.5rem 0;
}

.project-container {
    display: flex;
    flex-wrap: wrap;
}

.project {
    width: 25%;
    min-width: 300px;
    height: 600px;
    position: relative;
    margin: 1rem 0;
    z-index: 99;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

.filter-part {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    transition: height .2s ease;
}

.project-content {
    padding: 3rem 6rem;
    color: #fff;
    height: calc(100% - 6rem);
    cursor: pointer;
}

.project-divider {
    width: 100%;
    background-color: #fff;
    height: 2px;
    margin-bottom: 3rem;
}

.project-title {
    height: 150px;
    font-size: 26px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
}

.project-location {
    position: absolute;
    bottom: 30px;
    transition: top .2s ease;
}

.project-location span {
    margin-left: 10px;
    font-weight: 500;
}

.project-description {
    max-height: 200px;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
}

.project:hover .project-description, .project:hover .project-divider {
    display: none;
}

.project:hover .project-location {
    top: 180px;
}

.project:hover .filter-part {
    height: 220px;
}

@media only screen and (max-width: 1750px) {
    .project-content {
        padding: 3rem 4rem;
    }
}

@media only screen and (max-width: 1200px) {
    .project {
        width: 50%;
    }

    .project-content {
        padding: 3rem 8rem;
    }
}

@media only screen and (max-width: 992px) {
    .project-content {
        padding: 3rem 4rem;
    }
}

@media only screen and (max-width: 768px) {
    .project {
        width: 100%;
    }
}

@media only screen and (max-width: 576px) {
    .tabs button {
        font-size: 1rem;
    }
}