.loading-logo {
    max-width: 400px;
    max-height: 300px;
    animation: logo .5s linear infinite;
}

.mrg-auto {
    margin: 0 auto;
}

@keyframes logo {
    0%{
        opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }