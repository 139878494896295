.request-form-container {
    width: 33%;
    padding: 2rem;
    margin: 0 auto;
}

.request-form-container label {
    padding: 1rem 0;
    color: var(--baseColor);
    font-weight: 700;
}

.request-reason {
    font-size: .85rem;
    color: #fff;
    border-radius: 12px;
    font-weight: 600;
    padding: 1rem;
    background-color: var(--baseColor);
}

.mrg-b1 {
    margin-bottom: 1rem;
}

.success-alert2 {
    font-size: .85rem;
    color: #fff;
    border-radius: 12px;
    font-weight: 600;
    padding: 1rem;
    background-color: #155724;
}

.giris-yap-link {
    background-color: #fff;
    border: 1px solid var(--baseColor);
    border-radius: 50rem;
    padding: 15px 40px;
    outline: none;
    color: var(--baseColor);
    margin-top: 20px;
    cursor: pointer;
    text-align: center;
    line-height: .85;
    font-size: 14px;
}

.giris-yap-link:hover {
    background-color: var(--baseColor);
    color: #fff !important;
}

@media only screen and (max-width: 992px) {
    .request-form-container {
        width: 80%;
    }
}
